;(function ($) {

    'use strict';

    var $cookieBar = $('#cookieBar');

    // close
    $('#cookieBarClose').on('click', function(e) {
        $cookieBar.removeClass('active');
    });

    // Agree and refresh
    $('#cookieBarAgree').on('click', function(e) {
        Cookies.set('gdpr', 1, { expires: 90 });
        $cookieBar.removeClass('active');

        // reload page
        // setTimeout(function() {
        //     location.reload();
        // }, 500);
    });

    // Disagree
    $('#cookieDisagree').on('click', function(e) {
        Cookies.set('gdpr', -1, { expires: 90 });
        $cookieBar.removeClass('active');
    });

    if ($cookieBar.length) {
        if (Cookies.get('gdpr') != 1) {
            $cookieBar.addClass('active');
        }
    }

})(jQuery);